import React, { useContext } from 'react';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import { FirstLevelMenu, LeftContainer, RightContainer } from '../SubPageNavigationStyles';
import CtaSection from '../ui/CtaSection';
import MenuTriggerSection from '../ui/MenuTriggerSection';
import { Context } from '../../../context/Context';

export interface MainMenuProps {
  showSubmenu: boolean;
  showSubheadline: boolean;
  toggleShowSubmenu: () => void;
}

const MainMenu: React.FC<MainMenuProps> = (props) => {
  const notMobile = useMediaQuery('(min-width: 768px)');
  const { hasCta } = useContext(Context);

  return (
    <FirstLevelMenu>
      <LeftContainer>
        <MenuTriggerSection {...props} />
      </LeftContainer>
      {notMobile && hasCta && (
        <RightContainer>
          <CtaSection />
        </RightContainer>
      )}
    </FirstLevelMenu>
  );
};

export default MainMenu;
