import React, { useReducer, useRef, useEffect, useContext, useState, useCallback } from 'react';
import { useStickyMode, Context } from '../../context/Context';
import { Container } from './SubPageNavigationStyles';

import { debounce, setHeight } from '../../utils/utils';
import StickyButtons from './ui/StickyButtons';
import Submenu from './submenu/Submenu';
import MainMenu from './mainmenu/MainMenu';

const SubPageNavigation: React.FC = () => {
  const { isPopOverOpen, setPopOverState } = useContext(Context);

  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const secondLevelRef = useRef<HTMLDivElement>(null);
  const stickyButtonsRef = useRef<HTMLDivElement>(null);

  const [showSubmenu, toggleShowSubmenu] = useReducer((state: boolean) => !state, false);
  const [showSubheadline, setShowSubheadline] = useState(false);
  const stickymode = useStickyMode();
  const lastScrollTop = useRef(0);

  const renderStickyButtons = (show: boolean) => {
    if (stickyButtonsRef.current) {
      setHeight(stickyButtonsRef.current, show);
    }
  };

  const renderMobileMenu = () => {
    if (!mobileMenuRef.current) return;
    setHeight(mobileMenuRef.current, showSubmenu);
  };

  const renderSecondLevelMenu = () => {
    if (secondLevelRef !== null && secondLevelRef.current !== null) {
      setHeight(secondLevelRef.current, showSubmenu);
    }
  };

  const handleStickyButtonsOnScroll = () => {
    const currentScroll = window.scrollY;
    const element = stickyButtonsRef.current;

    if (!element) return;

    const isScrollingUp = currentScroll < lastScrollTop.current;

    window.requestAnimationFrame(() => {
      setHeight(element, isScrollingUp);
    });

    lastScrollTop.current = Math.max(0, currentScroll);
  };

  useEffect(() => {
    // Use window.innerWidth to get the correct value after mounting instead of
    // useMediaQuery which always returns `true` during SSR and hydration.
    const isNowMobile = window.innerWidth < 1024;
    if (!isNowMobile) {
      toggleShowSubmenu();
      setShowSubheadline(false);
    } else {
      setShowSubheadline(true);
    }
    renderMobileMenu();
    if (!showSubmenu) {
      // make a delay for ssr
      setTimeout(() => renderStickyButtons(true), 100);
    }
  }, []);

  useEffect(() => {
    renderMobileMenu();
    renderSecondLevelMenu();
  }, [showSubmenu]);

  const handleScroll = useCallback(
    debounce(() => {
      if (stickyButtonsRef.current) {
        handleStickyButtonsOnScroll();
      }
      if (showSubmenu && window.innerWidth >= 1024) {
        toggleShowSubmenu();
      }
      setShowSubheadline(true);
    }, 30),
    [showSubmenu],
  );

  const handleResize = useCallback(() => {
    if (showSubmenu && window.innerWidth >= 768) {
      toggleShowSubmenu();
      setShowSubheadline(true);
    }
    setPopOverState(false);
    renderStickyButtons(false);
  }, [showSubmenu, setPopOverState]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleScroll, handleResize]);

  useEffect(() => {
    if (isPopOverOpen && showSubmenu) {
      toggleShowSubmenu();
    }
  }, [isPopOverOpen]);

  return (
    <Container data-testid="subpage-navigation-container" isSticky={stickymode}>
      <MainMenu
        showSubmenu={showSubmenu}
        showSubheadline={showSubheadline}
        toggleShowSubmenu={toggleShowSubmenu}
      />
      <StickyButtons showSubmenu={showSubmenu} stickyButtonsRef={stickyButtonsRef} />
      <Submenu
        showSubmenu={showSubmenu}
        secondLevelRef={secondLevelRef}
        mobileMenuRef={mobileMenuRef}
      />
    </Container>
  );
};
export default SubPageNavigation;
