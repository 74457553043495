import React, { ForwardedRef, useContext } from 'react';
import { TextButton, Text, Divider } from '@oneaudi/unified-web-components';
import { LinkWithCurrentPage } from '../../../../ContentType';
import { Context } from '../../../../context/Context';
import {
  LinkList,
  LinkListItem,
  LinkListLink,
  MobileMenuButtonContainer,
  MobileMenuContainer,
  MobileMenuWrapper,
  UnderlinedText,
  MobileMenuButtonWrapper,
} from '../../SubPageNavigationStyles';
import { useTrackingManager } from '../../../../utils/useTrackingManager';
import VtpButtons from '../../ui/VtpButtons';

interface MobileMenuProps {
  mobileMenuRef: ForwardedRef<HTMLDivElement>;
  isActive: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ mobileMenuRef, isActive }) => {
  const {
    content: { cta, links, secondaryCta },
    theme,
    hasCta,
    renderPrimaryButton,
    renderVtpButtons,
    renderSecondaryButton,
  } = useContext(Context);
  const secondaryButton = secondaryCta?.customCta;
  const { click } = useTrackingManager();

  const renderLink = (
    { text, href, currentPage, opensInNewTab }: LinkWithCurrentPage,
    index: number,
  ) => {
    if (currentPage) {
      return (
        <LinkListItem key={text}>
          <UnderlinedText>
            <Text variant={{ type: 'copy', order: '2', style: 'normal' }}>{text}</Text>
          </UnderlinedText>
        </LinkListItem>
      );
    }
    return (
      <LinkListItem key={text}>
        <LinkListLink
          href={href}
          target={opensInNewTab ? '_blank' : '_self'}
          onClick={() => click('link', false, text, href, `${index + 1}`, '')}
        >
          <Text variant={{ type: 'copy', order: '2', style: 'normal' }}>{text}</Text>
        </LinkListLink>
      </LinkListItem>
    );
  };

  return (
    <MobileMenuContainer>
      <MobileMenuWrapper
        ref={mobileMenuRef}
        isDarkMode={theme === 'dark'}
        className={isActive ? 'show' : undefined}
      >
        {/* Link list / subpages */}
        {links && links.length > 0 && (
          <LinkList>{links.map((link, index) => renderLink(link, index))}</LinkList>
        )}
        {/* Cta section */}
        {hasCta && (
          <MobileMenuButtonWrapper>
            <Divider className="divider" />
            <MobileMenuButtonContainer>
              {renderPrimaryButton && (
                <TextButton
                  href={cta[0].href}
                  target={cta[0].opensInNewTab ? '_blank' : '_self'}
                  variant="primary"
                  isFullWidth
                  size="sm"
                  onClick={() => click('cta', true, cta[0].text, cta[0].href, '', 'primary')}
                >
                  {cta[0].text}
                </TextButton>
              )}
              {renderVtpButtons && (
                <>
                  {renderPrimaryButton && <div style={{ paddingTop: '8px' }} />}
                  <VtpButtons />
                </>
              )}
              {renderSecondaryButton && (
                <TextButton
                  href={secondaryButton.href}
                  variant="secondary"
                  isFullWidth
                  size="sm"
                  onClick={() =>
                    click('cta', true, secondaryButton.text, secondaryButton.href, '', 'secondary')
                  }
                >
                  {secondaryButton.text}
                </TextButton>
              )}
            </MobileMenuButtonContainer>
          </MobileMenuButtonWrapper>
        )}
      </MobileMenuWrapper>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
