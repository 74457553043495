import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { InitialState } from '../FeatureHubAppDefinition';

interface State {
  readonly initialState: InitialState;
}

export const Context = createContext<State>({} as State);

interface InitialStateContextProviderProps {
  readonly initialState: InitialState;
}

export const InitialStateContextProvider = ({
  children,
  initialState,
}: PropsWithChildren<InitialStateContextProviderProps>) => {
  return useMemo(
    () => (
      <Context.Provider
        value={{
          initialState,
        }}
      >
        {children}
      </Context.Provider>
    ),
    [initialState],
  );
};

export const useInitialState = () => useContext(Context).initialState;
