import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { TextButton, Popover } from '@oneaudi/unified-web-components';
import { UeElement } from '@oneaudi/falcon-tools';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import { Context } from '../../../context/Context';
import { useTrackingManager } from '../../../utils/useTrackingManager';
import VtpButtons from './VtpButtons';
import VtpLinks from './VtpLinks';
import { useI18n } from '../../../i18n';

export const PopoverWrapper = styled.div<{
  isMobile: boolean;
}>`
  width: ${(props) => (props.isMobile ? '100%' : '324px')};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const PopoverBody = styled.div`
  padding: 8px;
`;
export const TriggerButton = styled(TextButton)`
  white-space: nowrap;
`;

const VtpPopover = () => {
  const { setPopOverState, isPopOverOpen, content } = useContext(Context);
  const { secondaryNavigation } = useTrackingManager();
  const buttonText = content?.secondaryCta?.shopNowCta?.text;

  const targetRef = useRef(null);

  const lgScreenSize = useMediaQuery('(min-width: 1024px)');
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Popover
      id="vtp-popover"
      withTip={false}
      alignment="right"
      open={isPopOverOpen}
      onClose={() => setPopOverState(false)}
      triggerElement={
        <TriggerButton
          data-testid="popover-trigger-button"
          variant="secondary"
          icon={isPopOverOpen ? 'arrow-up' : 'arrow-down'}
          iconPosition="trailing"
          size={lgScreenSize ? 'md' : 'sm'}
          isFullWidth
          aria-label={isPopOverOpen ? useI18n('closeVtpPopover') : useI18n('openVtpPopover')}
          onClick={() => {
            setPopOverState(!isPopOverOpen);
            secondaryNavigation(!isPopOverOpen, buttonText);
          }}
          ref={targetRef}
        >
          <UeElement
            type="text"
            property="secondaryCta_shopNowCta_text"
            label="Secondary CTA (optional) -  Fill in for VTP Secondary CTA (optional) - Label"
          >
            {buttonText}
          </UeElement>
        </TriggerButton>
      }
      content={
        <PopoverWrapper isMobile={isMobile}>
          <PopoverBody>{isMobile ? <VtpButtons /> : <VtpLinks />}</PopoverBody>
        </PopoverWrapper>
      }
    />
  );
};
export default VtpPopover;
