import type { ContentServiceV1 } from '@oneaudi/content-service';
import { PropsWithChildren } from 'react';
import { RenderMode } from '@oneaudi/render-mode-service';
import { I18nMessages, Content } from '../ContentType';

export enum AppTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ContextProviderProps extends PropsWithChildren {
  readonly contentService: ContentServiceV1;
}

export interface VtpData {
  readonly newCarsText: string;
  readonly usedCarsText: string;
  readonly usedCarsAmount: number;
  readonly newCarsAmount: number;
  readonly newCarsUrl: string;
  readonly usedCarsUrl: string;
  readonly vtpLabel: string;
}
export interface ContextState {
  readonly theme: AppTheme;
  readonly renderMode: RenderMode;
  readonly content: Content;
  readonly vtpData: VtpData;
  readonly headline: string;
  readonly stickymode: boolean;
  readonly hasLinks: boolean;
  readonly hasCta: boolean;
  readonly renderPrimaryButton: boolean;
  readonly renderSecondaryButton: boolean;
  readonly renderVtpButtons: boolean;
  readonly i18nMessages: I18nMessages;
  readonly isPopOverOpen: boolean;
  readonly setPopOverState: (popOverState: boolean) => void;
}
