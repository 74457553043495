import { isContentFragment } from '@oneaudi/falcon-tools';
import {
  Content,
  HeadlessContent,
  LinkWithCurrentPage,
  SECONDARYOPTIONS,
  StickyMode,
} from '../ContentType';
import { AppTheme } from '../context/ContextTypes';

const defaultLinkText = 'Link text placeholder';
const defaultHref = 'https://www.audi.de';

export const mapLinks = (content: HeadlessContent): LinkWithCurrentPage[] =>
  content.fields.links?.map((link) => ({
    href: link.fields.href || defaultHref,
    text: link.fields.text || defaultLinkText,
    opensInNewTab: link.fields.opensInNewTab === true,
    currentPage: link.fields.currentPage === true,
  })) || [
    {
      text: defaultLinkText,
      href: defaultHref,
      opensInNewTab: false,
      currentPage: false,
    },
  ];

function validateLinks(links: unknown): boolean {
  return Array.isArray(links) && links.every((link) => isContentFragment(link));
}

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if (content && '__type' in content && content.__type === 'aem-headless') {
    const links = validateLinks(content.fields.links) ? mapLinks(content) : [];

    const cta =
      content.fields.cta_0_text &&
      content.fields.cta_0_text.length > 0 &&
      content.fields.cta_0_href &&
      content.fields.cta_0_href.length > 0
        ? [
            {
              text: content.fields.cta_0_text,
              href: content.fields.cta_0_href,
              opensInNewTab: content.fields.cta_0_opensInNewTab,
            },
          ]
        : [];

    const normalisedContent: Content = {
      headline: content.fields.headline || 'Headline Placeholder',
      links,
      secondaryCta: {
        secondaryOptions: content.fields.secondaryCta_secondaryOptions ?? 'none',
        shopNowCta:
          content.fields.secondaryCta_secondaryOptions === SECONDARYOPTIONS.VTP
            ? {
                text: content.fields.secondaryCta_shopNowCta_text ?? '',
                carlineId: content.fields.secondaryCta_shopNowCta_carlineId ?? '',
              }
            : { text: '', carlineId: '' },
        customCta:
          content.fields.secondaryCta_secondaryOptions === SECONDARYOPTIONS.CUSTOM
            ? {
                text: content.fields.secondaryCta_customCta_text ?? '',
                href: content.fields.secondaryCta_customCta_href ?? '',
              }
            : { text: '', href: '' },
      },
      cta,
      lightDarkMode: content.fields.lightDarkMode ?? AppTheme.DARK,
      stickyMode: content.fields.stickyMode ?? StickyMode.STICKY,
    };

    return normalisedContent;
  }

  return content as Content;
};
