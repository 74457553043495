(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@oneaudi/unified-web-common"), require("react"), require("react-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@oneaudi/unified-web-common", "react", "react-dom", "styled-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@oneaudi/unified-web-common"), require("react"), require("react-dom"), require("styled-components")) : factory(root["@oneaudi/unified-web-common"], root["react"], root["react-dom"], root["styled-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__536__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__514__, __WEBPACK_EXTERNAL_MODULE__694__) => {
return 