import { Stock } from '@oneaudi/onegraph-client';
import { AsyncStateHolder } from './FeatureHubAppDefinition';
import { AppTheme } from './context/ContextTypes';

export interface Link {
  readonly text: string;
  readonly href: string;
  readonly opensInNewTab?: boolean | null;
}

export interface SecondaryCta {
  readonly secondaryOptions: string;
  readonly shopNowCta: ShopNow;
  readonly customCta: CustomCta;
}

export interface ShopNow {
  readonly text: string;
  readonly carlineId: string;
}

export interface CustomCta {
  readonly text: string;
  readonly href: string;
}

export interface LinkWithCurrentPage extends Link {
  readonly currentPage: boolean | null;
}

export type CarlineAvailableStock = {
  readonly name: string;
  readonly newCars?: Stock;
  readonly usedCars?: Stock;
};

export interface ContentProps {
  readonly i18nMessages?: I18nMessages;
}

export enum StickyMode {
  STICKY = 'sticky',
  NONE = 'none',
}
export interface Content {
  readonly headline: string;
  readonly links: LinkWithCurrentPage[];
  readonly cta: Link[];
  readonly secondaryCta: SecondaryCta;
  readonly lightDarkMode: AppTheme;
  readonly stickyMode: StickyMode;
}

export interface HeadlessContent {
  readonly __type: string;
  readonly fields: {
    readonly headline: string;
    readonly cta_0_text: string | null;
    readonly cta_0_href: string | null;
    readonly cta_0_opensInNewTab: boolean | null;
    readonly secondaryCta_shopNowCta_text: string | null;
    readonly secondaryCta_shopNowCta_carlineId: string | null;
    readonly secondaryCta_customCta_text: string | null;
    readonly secondaryCta_customCta_href: string | null;
    readonly lightDarkMode: AppTheme;
    readonly stickyMode: StickyMode;
    readonly secondaryCta_secondaryOptions: string;
    readonly links: {
      readonly fields: LinkWithCurrentPage;
    }[];
  };
}

export enum SECONDARYOPTIONS {
  NONE = 'none',
  VTP = 'vtp',
  CUSTOM = 'custom',
}

export interface AsyncFeatureAppProps {
  readonly asyncStateHolder: AsyncStateHolder;
}

export type I18nMessages = Record<I18nMessageKey, string>;

export type I18nMessageKey =
  | 'newCars'
  | 'usedCars'
  | 'available'
  | 'openSubmenu'
  | 'closeSubmenu'
  | 'openVtpPopover'
  | 'closeVtpPopover';

export interface I18nTexts {
  [key: string]: string;
}
