import { TextButton } from '@oneaudi/unified-web-components';
import React, { useContext } from 'react';
import { Context } from '../../../context/Context';
import { useTrackingManager } from '../../../utils/useTrackingManager';
import VtpHeadline from './VtpHeadline';
import { VtpContainer } from '../SubPageNavigationStyles';

const VtpButtons = () => {
  const { vtpData } = useContext(Context);
  const { newCarsText, usedCarsText, newCarsAmount, newCarsUrl, usedCarsAmount, usedCarsUrl } =
    vtpData;
  const { click } = useTrackingManager();

  return (
    <VtpContainer>
      <VtpHeadline />
      {newCarsAmount !== 0 && newCarsUrl !== undefined && (
        <TextButton
          variant="secondary"
          href={newCarsUrl}
          isFullWidth
          onClick={() =>
            click(
              'link',
              true,
              newCarsText.replace(/\s?\(\d+\)/g, ''),
              newCarsUrl,
              `${newCarsAmount}`,
              'secondary',
            )
          }
          size="sm"
        >
          {newCarsText}
        </TextButton>
      )}
      {usedCarsAmount !== 0 && usedCarsUrl !== undefined && (
        <TextButton
          variant="secondary"
          href={usedCarsUrl}
          isFullWidth
          onClick={() =>
            click(
              'link',
              true,
              usedCarsText.replace(/\s?\(\d+\)/g, ''),
              usedCarsUrl,
              `${usedCarsAmount}`,
              'secondary',
            )
          }
          size="sm"
        >
          {usedCarsText}
        </TextButton>
      )}
    </VtpContainer>
  );
};

export default VtpButtons;
