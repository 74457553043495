import React, { ForwardedRef, useContext } from 'react';
import { RenderMode } from '@oneaudi/render-mode-service';
import { ThemeProvider } from '@oneaudi/unified-web-common';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import { SecondLevelMenu } from '../SubPageNavigationStyles';
import { Context } from '../../../context/Context';
import DesktopNavigationBar from './desktop/DesktopNavigationBar';
import MobileMenu from './mobile/MobileMenu';

export interface SubmenuProps {
  showSubmenu: boolean;
  secondLevelRef: ForwardedRef<HTMLDivElement>;
  mobileMenuRef: ForwardedRef<HTMLDivElement>;
}

const Submenu: React.FC<SubmenuProps> = ({ showSubmenu, secondLevelRef, mobileMenuRef }) => {
  const { stickymode, theme, hasLinks, hasCta, renderMode } = useContext(Context);
  const upToLargeViewport = useMediaQuery('(max-width: 1024px)');

  return (
    <>
      {renderMode === RenderMode.DEFAULT && (hasCta || hasLinks) && (
        <ThemeProvider theme={{ name: theme }}>
          {hasLinks && !upToLargeViewport && (
            <SecondLevelMenu isSticky={stickymode}>
              <DesktopNavigationBar secondLevelRef={secondLevelRef} isActive={showSubmenu} />
            </SecondLevelMenu>
          )}

          {upToLargeViewport && (hasCta || hasLinks) && (
            <MobileMenu mobileMenuRef={mobileMenuRef} isActive={showSubmenu} />
          )}
        </ThemeProvider>
      )}

      {/* 
      EDIT MODE: always render SecondLevelMenu and DesktopNavigationBar to enable editing in the Universal Editor.
    */}
      {renderMode === RenderMode.EDIT && (
        <ThemeProvider theme={{ name: theme }}>
          <SecondLevelMenu isSticky={stickymode}>
            <DesktopNavigationBar secondLevelRef={secondLevelRef} isActive={showSubmenu} />
          </SecondLevelMenu>
          {upToLargeViewport && (hasCta || hasLinks) && (
            <MobileMenu mobileMenuRef={mobileMenuRef} isActive={showSubmenu} />
          )}
        </ThemeProvider>
      )}
    </>
  );
};

export default Submenu;
