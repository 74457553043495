import { ButtonGroup } from '@oneaudi/unified-web-components';
import {
  SYS_BREAKPOINT_LG,
  SYS_BREAKPOINT_MD,
  SYS_COLOR_FOREGROUND_NEUTRAL_100_LIGHT,
  SYS_SPACE_RELATIVE_BP_XL_MD,
  SYS_COLOR_FOREGROUND_NEUTRAL_800_LIGHT,
  SYS_COLOR_CANVAS_INVERTED_LIGHT,
  SYS_COLOR_SURFACE_NEUTRAL_600_LIGHT,
  SYS_SPACE_RELATIVE_BP_XS_MD,
  SYS_BREAKPOINT_SM,
  SYS_SPACE_RELATIVE_BP_SM_XL,
  SYS_PAGE_MARGIN_SM,
  SYS_PAGE_MARGIN_MD,
  SYS_PAGE_MARGIN_XL,
  SYS_BREAKPOINT_XL,
  SYS_PAGE_MARGIN_XS,
  SYS_PAGE_MARGIN_LG,
  SYS_BREAKPOINT_2_XL,
  SYS_PAGE_MARGIN_2_XL,
  SYS_BREAKPOINT_XS,
  SYS_SPACE_RELATIVE_BP_XS_SM,
  SYS_SPACE_RELATIVE_BP_XS_LG,
} from '@oneaudi/unified-web-common';
import styled from 'styled-components';
import { isDarkTheme } from '../../utils/theme';

export const xsBreakPoint = parseInt(SYS_BREAKPOINT_XS, 10);
export const smBreakPoint = parseInt(SYS_BREAKPOINT_SM, 10);
export const mdBreakPoint = parseInt(SYS_BREAKPOINT_MD, 10);
export const lgBreakPoint = parseInt(SYS_BREAKPOINT_LG, 10);
export const xlBreakPoint = parseInt(SYS_BREAKPOINT_XL, 10);

export const Container = styled.div<{
  isSticky: boolean;
}>`
  --page-margin: ${SYS_PAGE_MARGIN_XS};
  --boxShadow: 0 5px 4px -4px rgba(0, 0, 0, 0.16);

  @media (min-width: ${SYS_BREAKPOINT_SM}) {
    --page-margin: ${SYS_PAGE_MARGIN_SM};
  }

  @media (min-width: ${SYS_BREAKPOINT_MD}) {
    --page-margin: ${SYS_PAGE_MARGIN_MD};
  }

  @media (min-width: ${SYS_BREAKPOINT_LG}) {
    --page-margin: ${SYS_PAGE_MARGIN_LG};
  }

  @media (min-width: ${SYS_BREAKPOINT_XL}) {
    --page-margin: ${SYS_PAGE_MARGIN_XL};
  }

  @media (min-width: ${SYS_BREAKPOINT_2_XL}) {
    --page-margin: ${SYS_PAGE_MARGIN_2_XL};
  }

  position: ${(props) => (props.isSticky ? 'sticky' : '')};
  top: ${(props) => (props.isSticky ? '0' : '')};

  box-shadow: var(--boxShadow);
  background: #fff;
  z-index: 99;
`;

/** **
 * MAINMENU
 *** */
export const FirstLevelMenu = styled.div`
  position: relative;
  padding-block: ${SYS_SPACE_RELATIVE_BP_XS_MD};
  padding-inline: var(--page-margin);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${lgBreakPoint}px) {
    padding-block: ${SYS_SPACE_RELATIVE_BP_XS_LG};
  }
`;

export const LeftContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  @media (min-width: ${mdBreakPoint}px) {
    width: auto;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

export const MenuTriggerSectionWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: ${SYS_COLOR_FOREGROUND_NEUTRAL_100_LIGHT};

  @media (min-width: ${mdBreakPoint}px) {
    height: 58px;
    width: auto;
    display: flex;
    align-items: center;
    margin-inline-end: ${SYS_PAGE_MARGIN_SM};
  }
`;

export const HeadlineWrapper = styled.div`
  @media (min-width: ${mdBreakPoint}px) {
    visibility: visible;
  }
  .subheadline {
    color: ${(props) => props.theme.sys.color.foreground.neutral['300']};
  }
`;

export const ToggleButton = styled.button`
  text-align: left;
  border: none;
  background-color: transparent;
  font-family: inherit;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${mdBreakPoint}px) {
    align-items: normal;
  }
`;

export const CtaWrapper = styled.div`
  display: none;
  @media (min-width: ${mdBreakPoint}px) {
    display: flex;
  }
`;

/** **
 * SUBMENU - Second level navigation Desktop
 *** */
export const DesktopNavigationWrapper = styled.div<{
  withCTA: boolean;
  isDarkMode: boolean;
}>`
  width: 100%;
  height: var(--height, 0);
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 99;
  box-sizing: border-box;
  visibility: hidden;
  transition: 400ms cubic-bezier(0.75, 0.02, 0.5, 1);
  transition-property: height, visibility;
  background: ${(props) =>
    props.isDarkMode ? SYS_COLOR_CANVAS_INVERTED_LIGHT : SYS_COLOR_SURFACE_NEUTRAL_600_LIGHT};
  box-shadow: var(--boxShadow);

  &.show {
    visibility: visible;
  }
  .adds-margin {
    margin-block: ${SYS_SPACE_RELATIVE_BP_XL_MD};
  }
  padding-inline: calc(var(--page-margin) - 15px);
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  > div {
    width: 100%;
  }
  align-items: center;
`;

export const StyledHeadline = styled.h1`
  margin: 0;
  color: hsla(33, 0%, 20%, 1);
  font-family: AudiType, sans-serif;
  letter-spacing: 0px;
  font-weight: 700;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  font-stretch: 105%;
`;

export const SecondLevelMenu = styled.div<{
  isSticky: boolean;
}>`
  transition: 400ms cubic-bezier(0.75, 0.02, 0.5, 1);
  transition-property: height;
  position: relative;
  margin: 0;
  z-index: 98;
  @media (max-width: ${lgBreakPoint - 1}px) {
    display: none;
  }
`;

export const NavigationItemWrapper = styled.span`
  color: ${({ theme }) =>
    isDarkTheme(theme)
      ? SYS_COLOR_FOREGROUND_NEUTRAL_800_LIGHT
      : SYS_COLOR_FOREGROUND_NEUTRAL_100_LIGHT};
`;

/** **
 * SUBMENU - Mobile menu
 *** */
export const MobileMenuContainer = styled.div`
  @media (min-width: ${lgBreakPoint}px) {
    display: none;
  }
`;

export const MobileMenuWrapper = styled.div<{
  isDarkMode: boolean;
}>`
  height: var(--height, 0);

  transition: 400ms cubic-bezier(0.75, 0.02, 0.5, 1);
  transition-property: height, visibility;
  visibility: hidden;
  overflow: hidden;
  z-index: 99;
  &.show {
    visibility: visible;
    overflow: auto;
  }
  background: ${(props) =>
    props.isDarkMode ? SYS_COLOR_CANVAS_INVERTED_LIGHT : SYS_COLOR_SURFACE_NEUTRAL_600_LIGHT};
  box-shadow: var(--boxShadow);

  position: relative;
  z-index: 98;
  width: 100%;
  @media (min-width: ${lgBreakPoint}px) {
    display: none;
  }
`;

export const LinkList = styled.ul`
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  top: 100%;
  z-index: 99;
  width: 100vw;
  box-sizing: border-box;
  @media (min-width: ${lgBreakPoint}px) {
    display: none;
  }
`;

export const LinkListItem = styled.li`
  padding-block: ${SYS_SPACE_RELATIVE_BP_XS_SM};
  margin-inline: var(--page-margin);

  &:first-child {
    margin-block-start: ${SYS_SPACE_RELATIVE_BP_XS_SM};
  }
  &:last-child {
    margin-block-end: ${SYS_SPACE_RELATIVE_BP_XS_MD};
  }
`;

export const LinkListLink = styled.a`
  text-decoration: none;
`;

export const UnderlinedText = styled.span`
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme }) => (isDarkTheme(theme) ? 'white' : 'black')};
  padding-bottom: 4px;
  display: inline-block;
  margin-block-end: -6px;
  > span {
    color: ${({ theme }) =>
      isDarkTheme(theme)
        ? SYS_COLOR_FOREGROUND_NEUTRAL_800_LIGHT
        : SYS_COLOR_FOREGROUND_NEUTRAL_100_LIGHT};
  }
`;

export const MobileMenuButtonWrapper = styled.div`
  .divider {
    background: var(--Sys-Color-Surface-Neutral-400, #b3b3b3);
  }
  @media (min-width: ${mdBreakPoint}px) {
    display: none;
  }
`;

export const MobileMenuButtonContainer = styled.div`
  max-width: 100%;
  display: grid;
  gap: 8px;
  padding: 0;
  margin-block: ${SYS_SPACE_RELATIVE_BP_SM_XL};
  margin-inline: var(--page-margin);
`;

/** **
 * STICKY BUTTONS
 *** */
export const StyledStickyButtons = styled.div`
  background: #fff;
  position: absolute;
  width: 100%;
  z-index: 98;
  padding-inline: var(--page-margin);
  box-sizing: border-box;
  height: var(--height, 0);
  transition: 400ms cubic-bezier(0.75, 0.02, 0.5, 1);
  transition-property: height, visibility;
  overflow: hidden;
  box-shadow: var(--boxShadow);
`;

export const StickyButtonsContainer = styled.div`
  margin: 0 0 16px 0;
  display: grid;
  gap: 8px;
`;

/** **
 * VtpContainer
 *** */
export const VtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: ${mdBreakPoint}px) {
    gap: 16px;
  }
`;
