import React, { ForwardedRef, useContext } from 'react';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import { StickyButtonsContainer, StyledStickyButtons } from '../SubPageNavigationStyles';
import { Context } from '../../../context/Context';
import { CtaPrimary, CtaSecondary } from './CallToAction';
import VtpPopover from './VtpPopOver';

export interface StickyButtonsProps {
  showSubmenu: boolean;
  stickyButtonsRef: ForwardedRef<HTMLDivElement>;
}

const StickyButtons: React.FC<StickyButtonsProps> = ({ showSubmenu, stickyButtonsRef }) => {
  const upToMediumViewport = useMediaQuery('(max-width: 767px)');
  const { hasCta, renderPrimaryButton, renderVtpButtons, renderSecondaryButton, content } =
    useContext(Context);
  const { cta } = content;

  if (!upToMediumViewport || !hasCta || showSubmenu) return null;

  return (
    <StyledStickyButtons ref={stickyButtonsRef}>
      <StickyButtonsContainer>
        {renderPrimaryButton && (
          <CtaPrimary href={cta[0].href} text={cta[0].text} opensInNewTab={cta[0].opensInNewTab} />
        )}
        {renderVtpButtons && <VtpPopover />}
        {renderSecondaryButton && <CtaSecondary {...content.secondaryCta.customCta} />}
      </StickyButtonsContainer>
    </StyledStickyButtons>
  );
};

export default StickyButtons;
