import React, { ForwardedRef, useContext } from 'react';
import { RenderMode } from '@oneaudi/render-mode-service';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { NavigationBar, NavigationBarProps, Text } from '@oneaudi/unified-web-components';
import { Context } from '../../../../context/Context';
import { useTrackingManager } from '../../../../utils/useTrackingManager';
import { DesktopNavigationWrapper } from '../../SubPageNavigationStyles';

interface DesktopLinksNavigationProps {
  secondLevelRef: ForwardedRef<HTMLDivElement>;
  isActive: boolean;
}

const DesktopLinksNavigation: React.FC<DesktopLinksNavigationProps> = ({
  secondLevelRef,
  isActive,
}) => {
  const { click } = useTrackingManager();
  const { renderMode, theme, hasCta } = useContext(Context);
  const {
    content: { links },
  } = useContext(Context);

  if (links.length === 0 && renderMode === RenderMode.DEFAULT) {
    return null;
  }

  const navItems: NavigationBarProps['items'] = links.map(
    ({ text, href, opensInNewTab, currentPage }, i) => ({
      label: (
        <Text variant={{ type: 'copy', order: '1', style: 'normal' }}>
          <UeReference key={text} propertyPath={`links.${i}`} behavior="component">
            <UeElement type="text" label="Sub Pages">
              {text}
            </UeElement>
          </UeReference>
        </Text>
      ),
      href,
      target: opensInNewTab ? '_blank' : undefined,
      rel: opensInNewTab ? 'noopener noreferrer' : undefined,
      active: !!currentPage,
      icon: undefined,
      iconPosition: 'leading',
      onClick: () => click('link', false, text, href, `${i + 1}`, ''),
    }),
  );

  return (
    <DesktopNavigationWrapper
      withCTA={hasCta}
      ref={secondLevelRef}
      className={isActive && navItems.length > 0 ? 'show' : undefined}
      isDarkMode={theme === 'dark'}
    >
      <UeContainer propertyPath="links" label="Sub Pages">
        <NavigationBar
          id="nav-bar__scrolling"
          scrollButtons={{
            left: { ariaLabel: 'Scroll left' },
            right: { ariaLabel: 'Scroll right' },
          }}
          size="sm"
          items={navItems}
          className="adds-margin"
        />
      </UeContainer>
    </DesktopNavigationWrapper>
  );
};

export default DesktopLinksNavigation;
