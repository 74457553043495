import React, { useContext } from 'react';
import { CtaWrapper, StyledButtonGroup } from '../SubPageNavigationStyles';
import { CtaPrimary, CtaSecondary } from './CallToAction';
import { Context } from '../../../context/Context';
import VtpPopover from './VtpPopOver';

const CtaSection = () => {
  const {
    content: { cta, secondaryCta },
    renderPrimaryButton,
    renderVtpButtons,
    renderSecondaryButton,
  } = useContext(Context);

  return (
    <CtaWrapper>
      <StyledButtonGroup>
        {renderPrimaryButton && <CtaPrimary {...cta[0]} />}
        {renderVtpButtons && <VtpPopover />}
        {renderSecondaryButton && <CtaSecondary {...secondaryCta.customCta} />}
      </StyledButtonGroup>
    </CtaWrapper>
  );
};

export default CtaSection;
