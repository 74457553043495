import { Content, SECONDARYOPTIONS, StickyMode } from '../ContentType';
import { AppTheme, VtpData } from '../context/ContextTypes';
import { InitialState } from '../FeatureHubAppDefinition';
import { matchI18n } from '../i18n';

export const setHeight = (element: HTMLUListElement | HTMLDivElement, showElement: boolean) => {
  if (!element) return;
  window.requestAnimationFrame(() => {
    element.style.setProperty('--height', showElement ? `${element.scrollHeight}px` : '0px');
  });
};

export const debounce = <T extends (...args: unknown[]) => void>(
  func: T,
  delay: number,
): ((...args: Parameters<T>) => void) => {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<T>): void => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  };
};

export const getStockData = (initialState: InitialState): VtpData => {
  const { i18nMessages, carlineAvailableStock: stock } = initialState;

  const usedCarsAmount = stock?.usedCars?.amount || 0;
  const newCarsAmount = stock?.newCars?.amount || 0;
  const newCarsUrl = stock?.newCars?.url || '';
  const usedCarsUrl = stock?.usedCars?.url || '';

  const newCarsText = matchI18n('newCars', i18nMessages, {
    value: newCarsAmount.toString(),
  });

  const usedCarsText = matchI18n('usedCars', i18nMessages, {
    value: usedCarsAmount.toString(),
  });

  const vtpLabel = `${matchI18n('available', i18nMessages)} ${stock?.name}` || '';

  return {
    newCarsText,
    usedCarsText,
    usedCarsAmount,
    newCarsAmount,
    newCarsUrl,
    usedCarsUrl,
    vtpLabel,
  };
};

export const getHeadline = (content: Content, initialState: InitialState) => {
  if (
    content.secondaryCta?.secondaryOptions === SECONDARYOPTIONS.VTP &&
    initialState.carlineAvailableStock
  ) {
    return initialState.carlineAvailableStock.name;
  }
  return content.headline;
};

export const shouldRenderCTAs = (content: Content) => {
  return !!(
    (content.cta?.[0]?.text && content.cta[0]?.href) ||
    content.secondaryCta?.secondaryOptions === SECONDARYOPTIONS.CUSTOM ||
    content.secondaryCta?.secondaryOptions === SECONDARYOPTIONS.VTP
  );
};

export const shouldRenderPrimaryButton = (content: Content) => {
  return !!(content.cta?.[0]?.text && content.cta?.[0]?.href);
};

export const shouldRenderVtpButtons = (content: Content, initialState: InitialState) => {
  return !!(
    content.secondaryCta?.secondaryOptions === SECONDARYOPTIONS.VTP &&
    content.secondaryCta?.shopNowCta?.carlineId &&
    initialState.carlineAvailableStock
  );
};

export const shouldRenderSecondaryButton = (content: Content) => {
  return !!(
    content.secondaryCta?.secondaryOptions === SECONDARYOPTIONS.CUSTOM &&
    content.secondaryCta?.customCta?.text &&
    content.secondaryCta?.customCta?.href
  );
};

export const hasStickyMode = (content: Content) => content.stickyMode !== StickyMode.NONE;
export const getTheme = (content: Content) => content.lightDarkMode ?? AppTheme.DARK;
