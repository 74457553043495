import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { Logger } from '@feature-hub/core';
import { createAsyncCarlineFetch } from './getCarlines';
import { CarlineAvailableStock, Content, I18nMessages } from '../ContentType';
import { InitialState } from '../FeatureHubAppDefinition';
import { fetchI18nMessages } from '../i18n';

let i18nMessages: I18nMessages;

export async function createInitialState(
  oneGraphService: OneGraphServiceV1,
  localeService: GfaLocaleServiceV1,
  content: Content,
  i18nService: I18NServiceV1,
  logger: Logger,
): Promise<InitialState> {
  if (!i18nMessages) {
    i18nMessages = await fetchI18nMessages(localeService, i18nService);
  }

  let availableStock: CarlineAvailableStock | undefined;

  if (
    content.secondaryCta &&
    content.secondaryCta.secondaryOptions === 'vtp' &&
    content.secondaryCta.shopNowCta &&
    content.secondaryCta.shopNowCta.carlineId &&
    content.secondaryCta.shopNowCta.text
  ) {
    const carline = await createAsyncCarlineFetch(
      oneGraphService,
      localeService,
      content.secondaryCta.shopNowCta.carlineId,
      logger,
    );
    if (carline && carline.availableStock) {
      availableStock = {
        name: carline.name,
        newCars: carline.availableStock.newCars ?? undefined,
        usedCars: carline.availableStock.usedCars ?? undefined,
      };
    }

    return {
      carlineAvailableStock: availableStock,
      i18nMessages,
    };
  }
  return {
    i18nMessages,
  };
}

/**
 * Helper function to deserialize the state of the feature app. It converts serialized
 * ReactNodeArray Entries that aren't string to FootnoteReference components
 * @param state
 */

export function deserializeState(state: string): InitialState {
  const props = JSON.parse(state);

  return {
    ...props,
  };
}
