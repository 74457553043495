import { useContext, useMemo } from 'react';
import { FeatureAppContext } from '../context/FeatureAppContext';

export interface TrackingManager {
  ready(version: string): void;
  click(
    linkType: string,
    isCTA: boolean,
    label: string,
    targetURL: string,
    pos: string,
    value?: string,
  ): void;
  navigation(isActive: boolean): void;
  secondaryNavigation(isActive: boolean, name?: string): void;
}

export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>,
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'subpage-navigation',
    currentURL: `${host}${pathname}`,
  };
  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function createComponentPayload(version: string) {
  return {
    componentInfo: {
      implementer: 2,
      version,
    },
  };
}

export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = useContext(FeatureAppContext);

  return useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService?.evaluateLinkType) {
        const payload = createTrackingPayload(...args);
        logger.debug('Sending tracking event', payload);
        trackingService.track(payload);
      }
    }

    function trackWithComponent(
      version: string,
      ...args: Parameters<typeof createTrackingPayload>
    ): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        const componentPayload = createComponentPayload(version);
        if (logger) logger.debug('Sending component info', componentPayload);
        trackingService.track({ ...payload, componentUpdate: componentPayload });
      }
    }
    return {
      ready(version) {
        trackWithComponent(version, 'feature_app_ready', 'subpage-navigation - feature app ready', {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },
      click(linkType, isCTA, label, targetURL, pos, value) {
        const evaluatedLinkType = trackingService?.evaluateLinkType?.(targetURL) || 'unknown';

        track(evaluatedLinkType, `subpage navigation - click on ${isCTA ? 'cta' : 'link'}`, {
          label,
          targetURL,
          clickID: '',
          elementName: linkType === 'link' ? 'text link' : 'button',
          value,
          pos,
        });
      },

      navigation(isActive) {
        const action = isActive ? 'close' : 'open';
        track('navigation', `subpage navigation - ${action} navigation`, {
          label: '',
          targetURL: '',
          clickID: '',
          elementName: 'drop down',
          value: '',
          pos: '',
        });
      },

      secondaryNavigation(isPopOver, name) {
        const action = isPopOver ? 'open' : 'close';
        track('navigation', `subpage navigation - ${action} drop down`, {
          label: name,
          targetURL: '',
          clickID: '',
          elementName: 'drop down',
          value: '2',
          pos: '',
        });
      },
    };
  }, [trackingService]);
}
