import {
  SEM_COLOR_ACTION_PRIMARY_SURFACE_STROKE_DEFAULT_LIGHT,
  SYS_SPACE_FIXED_150,
} from '@oneaudi/unified-web-common';
import React from 'react';
import styled from 'styled-components';
import { mdBreakPoint } from '../SubPageNavigationStyles';

const BaseIcon = styled.svg`
  grid-column: arrow-icon;
  color: ${SEM_COLOR_ACTION_PRIMARY_SURFACE_STROKE_DEFAULT_LIGHT};
  transform: rotateZ(0deg);
  transform-origin: center;
  pointer-events: none;
  flex-shrink: 0;

  &.rotated-90 {
    transform: rotateZ(90deg);
  }
`;

const StyledIcon = styled(BaseIcon)`
  margin-inline-start: ${SYS_SPACE_FIXED_150};
  width: 32px;
  height: 32px;
  &.rotated-180 {
    transform: rotateZ(-180deg);
  }

  @media (min-width: ${mdBreakPoint}px) {
    width: 24px;
    height: 24px;
  }
`;

export interface ArrowIconProps {
  readonly rotated: boolean;
}
const StyledPath = styled.path`
  stroke: ${(props) => props.theme.sem.color.ui.foreground.icons.default};
  stroke-width: 1.5;
  fill: none;
  fill-rule: evenodd;
`;

export const ArrowIcon: React.FC<ArrowIconProps> = ({ rotated }) => (
  <StyledIcon
    className={rotated ? `rotated-180` : ''}
    width="32"
    height="32"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
  >
    <StyledPath d="M7 10L12.5 15.5 18 10" />
  </StyledIcon>
);
