import React from 'react';
import { TextButton } from '@oneaudi/unified-web-components';
import { UeElement } from '@oneaudi/falcon-tools';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import styled from 'styled-components';
import { Link } from '../../../ContentType';
import { useTrackingManager } from '../../../utils/useTrackingManager';

interface CtaProps extends Link {
  variant: 'primary' | 'secondary';
  label: string;
  property: string;
}

const StyledTextButton = styled(TextButton)`
  white-space: nowrap;
`;

const CtaButton: React.FC<CtaProps> = ({ href, text, opensInNewTab, variant, label, property }) => {
  const lgScreenSize = useMediaQuery('(min-width: 1024px)');
  const { click } = useTrackingManager();
  return (
    <StyledTextButton
      href={href}
      target={opensInNewTab ? '_blank' : '_self'}
      variant={variant}
      isFullWidth
      size={lgScreenSize ? 'md' : 'sm'}
      onClick={() => click('cta', true, text, href, '', variant)}
    >
      <UeElement type="text" label={label} property={property}>
        {text}
      </UeElement>
    </StyledTextButton>
  );
};

export const CtaPrimary: React.FC<Link> = ({ href, text, opensInNewTab }) => (
  <CtaButton
    href={href}
    text={text}
    opensInNewTab={opensInNewTab}
    variant="primary"
    label="Primary CTA (optional) - Label"
    property="cta_text"
  />
);

export const CtaSecondary: React.FC<Link> = ({ href, text, opensInNewTab }) => (
  <CtaButton
    href={href}
    text={text}
    opensInNewTab={opensInNewTab}
    variant="secondary"
    label="Secondary CTA (optional) - Fill in for a Custom Secondary CTA (optional) - Label"
    property="secondaryCta_customCta_text"
  />
);
