/* eslint-disable consistent-return */

import React, { useContext, useEffect, useState } from 'react';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';

import pkg from '../../package.json';
import SubPageNavigation from './components/subpage-navigation/SubPageNavigation';
import { InitialState } from './FeatureHubAppDefinition';
import { AsyncFeatureAppProps } from './ContentType';
import { useTrackingManager } from './utils/useTrackingManager';
import { InitialStateContextProvider } from './context/InitialStateContext';
import { mapHeadlessContent } from './utils/mapHeadlessContent';
import { createInitialState } from './utils/stateUtils';
import { ContextProvider } from './context/Context';
import { FeatureAppContext } from './context/FeatureAppContext';

const SubpageNavigationFeatureApp = () => {
  const trackingManager = useTrackingManager();

  useEffect(() => {
    trackingManager.ready(pkg.version);
  }, []);

  return <SubPageNavigation />;
};

const AsyncFeatureApp: React.FC<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  const { oneGraphService, localeService, contentService, i18nService, renderModeService, logger } =
    useContext(FeatureAppContext);

  const [state, setState] = React.useState<InitialState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  const [initialContent] = useState(contentService.getContent());
  const content = mapHeadlessContent(initialContent);

  if (!initialContent) return;

  useEffect(() => {
    const mounted = true;

    // when asyncStateHolder is a function it means the state could not be properly
    // serialized by the server and it is not available on the client. In that case
    // this effect will try to fetch the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((newState) => mounted && setState(newState));
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(oneGraphService, localeService, content, i18nService, logger).then(
        (newState) => {
          if (mounted) {
            setState(newState);
          }
        },
      );
    }

    return () => {
      mounted = false;
    };
  }, [initialContent]);

  if (!state) {
    return null;
  }

  return (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <InitialStateContextProvider initialState={state}>
        <ContextProvider contentService={contentService}>
          <SubpageNavigationFeatureApp />
        </ContextProvider>
      </InitialStateContextProvider>
    </UniversalEditorProvider>
  );
};

export default AsyncFeatureApp;
