import type { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Logger } from '@feature-hub/core';
import { CarlineStructureCarline } from '../../web/generated/onegraph';
import CarlineQuery from './carline-query.graphql';

export const createAsyncCarlineFetch = async (
  oneGraphServiceV1: OneGraphServiceV1,
  { countryCode: country, language }: GfaLocaleServiceV1,
  carlineId: string,
  logger: Logger,
): Promise<CarlineStructureCarline | null> => {
  try {
    const oneGraphClient = oneGraphServiceV1.getApolloClient();

    const {
      data: { carline },
    } = await oneGraphClient.query({
      query: CarlineQuery,
      context: {
        consumerId: `${__FEATURE_APP_NAME__}@${__FEATURE_APP_VERSION__}`,
      },
      variables: {
        identifier: {
          country,
          language,
          id: carlineId,
        },
      },
    });
    return carline;
  } catch (error) {
    logger.info('Error fetching carline:', error);
    return null;
  }
};
