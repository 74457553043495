import React, { useContext, useEffect } from 'react';
import { UeElement } from '@oneaudi/falcon-tools';
import { Headline, Text } from '@oneaudi/unified-web-components';
import { useMediaQuery } from '@oneaudi/feature-app-utils';
import { Context } from '../../../context/Context';
import { useTrackingManager } from '../../../utils/useTrackingManager';
import { LinkWithCurrentPage } from '../../../ContentType';

import {
  MenuTriggerSectionWrapper,
  HeadlineWrapper,
  ToggleButton,
  StyledHeadline,
} from '../SubPageNavigationStyles';
import { ArrowIcon } from './ArrowIcon';
import { useI18n } from '../../../i18n';
import { MainMenuProps } from '../mainmenu/MainMenu';

const setSelected = (linkItems: LinkWithCurrentPage[] | undefined) => {
  const selected =
    linkItems
      ?.filter((item) => item.currentPage)
      .map((item) => item.text)
      .toString() || '';

  return selected;
};

const MenuTriggerSection: React.FC<MainMenuProps> = ({
  showSubmenu,
  showSubheadline,
  toggleShowSubmenu,
}) => {
  const { navigation } = useTrackingManager();
  const {
    content: { links },
    headline,
    setPopOverState,
    hasLinks,
    hasCta,
  } = useContext(Context);

  const handleNavClick = () => {
    setPopOverState(false);
    toggleShowSubmenu();
    navigation(showSubmenu);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && showSubmenu) {
      setPopOverState(false);
      toggleShowSubmenu();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showSubmenu]);
  const upToMediumViewport = useMediaQuery('(max-width: 767px)');

  return (
    <MenuTriggerSectionWrapper>
      <ToggleButton
        onClick={handleNavClick}
        aria-label={showSubmenu ? useI18n('closeSubmenu') : useI18n('openSubmenu')}
      >
        <HeadlineWrapper>
          {upToMediumViewport ? (
            <StyledHeadline>{headline}</StyledHeadline>
          ) : (
            <Headline
              tag="h1"
              variant={{ type: 'headline', order: '4', weight: 'normal' }}
              className="headline"
            >
              <UeElement type="text" property="headline" label="Main Page Title">
                {headline}
              </UeElement>
            </Headline>
          )}
          {hasLinks && showSubheadline && (
            <Text variant={{ type: 'copy', order: '2', style: 'normal' }} className="subheadline">
              {setSelected(links)}
            </Text>
          )}
        </HeadlineWrapper>
        {((!upToMediumViewport && hasLinks) ||
          (upToMediumViewport && hasLinks) ||
          (upToMediumViewport && hasCta)) && <ArrowIcon rotated={showSubmenu} />}
      </ToggleButton>
    </MenuTriggerSectionWrapper>
  );
};

export default MenuTriggerSection;
