import { Text } from '@oneaudi/unified-web-components';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';
import { mdBreakPoint } from '../SubPageNavigationStyles';

const StyledText = styled(Text)`
  margin-bottom: 8px;
  @media (min-width: ${mdBreakPoint}px) {
    margin-bottom: 0;
  }
`;

const VtpHeadline = () => {
  const {
    vtpData: { vtpLabel },
  } = useContext(Context);

  return (
    <StyledText tag="span" variant={{ type: 'copy', order: '2', style: 'normal' }}>
      {vtpLabel}
    </StyledText>
  );
};

export default VtpHeadline;
