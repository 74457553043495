import type { FC, PropsWithChildren } from 'react';
import React, { useMemo, createContext, useContext, useEffect, useState } from 'react';
import { RenderMode } from '@oneaudi/render-mode-service';
import { type Content, type I18nMessages } from '../ContentType';
import { mapHeadlessContent } from '../utils/mapHeadlessContent';
import type { AppTheme, ContextProviderProps, ContextState } from './ContextTypes';
import { FeatureAppContext } from './FeatureAppContext';
import { useInitialState } from './InitialStateContext';
import {
  getHeadline,
  getStockData,
  getTheme,
  hasStickyMode,
  shouldRenderCTAs,
  shouldRenderPrimaryButton,
  shouldRenderSecondaryButton,
  shouldRenderVtpButtons,
} from '../utils/utils';

const i18nMessages: I18nMessages = {
  usedCars: '',
  newCars: '',
  available: '',
  openSubmenu: '',
  closeSubmenu: '',
  openVtpPopover: '',
  closeVtpPopover: '',
};

export const Context = createContext<ContextState>({} as ContextState);

export const ContextProvider: FC<ContextProviderProps> = ({
  contentService,
  children,
}: PropsWithChildren<ContextProviderProps>) => {
  const { renderModeService } = useContext(FeatureAppContext);

  const initialContent = contentService.getContent();
  const initialState = useInitialState();

  const renderMode = renderModeService?.getRenderMode?.() || RenderMode.DEFAULT;

  const mappedContent = mapHeadlessContent(initialContent);
  const [content, setContent] = useState<Content>(mappedContent);
  const vtpData = getStockData(initialState);
  const [headline, setHeadline] = useState<string>(getHeadline(content, initialState));
  const [stickymode, setSticky] = useState<boolean>(hasStickyMode(content));
  const [hasLinks, setHasLinks] = useState<boolean>(content.links?.length > 0);
  const [hasCta, setHasCta] = useState<boolean>(shouldRenderCTAs(content));
  const [renderPrimaryButton, setRenderPrimaryButton] = useState<boolean>(
    shouldRenderPrimaryButton(content),
  );
  const [renderSecondaryButton, setRenderSecondaryButton] = useState<boolean>(
    shouldRenderSecondaryButton(content),
  );
  const [renderVtpButtons, setRenderVtpButtons] = useState<boolean>(
    shouldRenderVtpButtons(content, initialState),
  );

  const [theme, setTheme] = useState<AppTheme>(getTheme(content));

  const [isPopOverOpen, setIsPopOverOpen] = useState<boolean>(false);

  const setPopOverState = (popOverState: boolean) => {
    setIsPopOverOpen(popOverState);
  };

  useEffect(() => {
    let mounted = true;

    const contentChangeListener = () => {
      if (mounted) {
        const newInitialContent = contentService.getContent();
        const newContent = mapHeadlessContent(newInitialContent);

        setContent(newContent);
        setHeadline(getHeadline(newContent, initialState));
        setSticky(hasStickyMode(newContent));
        setHasLinks(newContent.links?.length > 0);
        setHasCta(shouldRenderCTAs(newContent));
        setRenderPrimaryButton(shouldRenderPrimaryButton(newContent));
        setRenderVtpButtons(shouldRenderVtpButtons(newContent, initialState));
        setRenderSecondaryButton(shouldRenderSecondaryButton(newContent));

        setTheme(getTheme(newContent));
      }
    };

    contentService.onContentChange(contentChangeListener);

    return () => {
      mounted = false;
      contentService.removeOnContentChange(contentChangeListener);
    };
  }, []);

  return useMemo(
    () => (
      <Context.Provider
        value={{
          content,
          theme,
          renderMode,
          vtpData,
          headline,
          stickymode,
          hasLinks,
          hasCta,
          renderPrimaryButton,
          renderSecondaryButton,
          renderVtpButtons,
          i18nMessages,
          isPopOverOpen,
          setPopOverState,
        }}
      >
        {children}
      </Context.Provider>
    ),
    [
      renderMode,
      content,
      theme,
      vtpData,
      headline,
      stickymode,
      hasLinks,
      hasCta,
      renderPrimaryButton,
      renderSecondaryButton,
      renderVtpButtons,
      i18nMessages,
      isPopOverOpen,
      setPopOverState,
    ],
  );
};

export const useStickyMode = () => useContext(Context).stickymode;
